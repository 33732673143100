





































import {
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRefs
} from "vue";
import { getVatRateTypes } from "@/api/infoteka";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import PersonItem from "@/components/personItem/index.vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import useVuelidate from "@vuelidate/core";
import PlaceItemLtl from "@/components/order/places/PlaceItemLtl.vue";
import OrderService from "@/models/order/Order.service";
import { useOrderFieldsInput } from "@/components/order/useOrderFieldsInput";
import ExecutorOrganization from "@/components/order/ExecutorOrganization.vue";
import ForwardingOptions from "@/components/order/ForwardingOptions.vue";
import Route from "@/components/order/Route/Route.vue";
import { useAutocompleteStore } from "@/store/useAutocompleteStore";

export default defineComponent({
  name: "OrderItemLtl",
  components: {
    Route,
    ForwardingOptions,
    ExecutorOrganization,
    PlaceItemLtl,
    PersonItem
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    },
    validateAfterMount: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props) {
    const { order } = toRefs(props);
    const orderService = new OrderService();
    const autocompleteStore = useAutocompleteStore();

    const {
      onPlaceAssessedValueInput,
      onPlaceDimensionsInput
    } = useOrderFieldsInput(order);

    const taxesTypes = ref<ListItemInterface[]>([]);

    const v$ = useVuelidate();

    const addPlace = async () => {
      orderService.addPlaceToOrder(order.value);

      await nextTick();
      v$.value.$touch();
    };
    function removePlace(placeIdx: number) {
      orderService.removePlace(order.value, placeIdx);
    }

    const fetchOrganization = (data: {
      type: OrganizationTypeEnum;
      parentId?: string;
    }) => {
      autocompleteStore.fetchOrganization(data);
    };
    const loadTaxesTypes = async () => {
      taxesTypes.value = await getVatRateTypes();
    };

    onMounted(() => {
      loadTaxesTypes();
      fetchOrganization({ type: OrganizationTypeEnum.Customer });

      if (!props.validateAfterMount) return;

      v$.value.$touch();
    });

    return {
      taxesTypes,
      v$,
      onPlaceDimensionsInput,
      onPlaceAssessedValueInput,
      addPlace,
      removePlace
    };
  }
});
